.goods-list {
    min-height: calc(100% - 40px);
    padding: 15px 20px; 
    background-color: #ffffff;
  
    .filters {
      margin-bottom: 10px;
    }
  
    .ant-table-wrapper {
      overflow-x: auto;
    }
  }