.goods-list-indicator-config-content {
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 10px;
    .ant-checkbox-wrapper {
      margin-right: 16px;
      margin-bottom: 8px;
      min-width: 150px;
    }
  }
  
  .indicator-group {
    margin-bottom: 20px;
  }
  
  .indicator-group h3 {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .indicator-group h3 .anticon {
    margin-right: 8px;
  }
  
  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  
  