.header-module 
    display: flex
    align-items: center
    justify-content: space-between
    height: 60px
    padding-right: 20px
    box-sizing: border-box
    background: #ffffff
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
    border-bottom: 2px solid #e8e8e8
    .menu-icon 
        display: block
        width: 60px
        height: 100%
    .fullscreen-icon
        margin-right: 15px





