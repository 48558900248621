.short-video-center {
    min-height: calc(100% - 40px);
    padding: 15px 20px; 
    background-color: #ffffff;
  
    .filters {
      margin-bottom: 10px;
    }
  
    .ant-table-wrapper {
      overflow-x: auto;
    }
  }

  .statistics-carousel-container {
    .ant-carousel {
      .slick-dots {
        bottom: 0px;
        
        li {
          width: 20px;
          height: 4px;
          margin: 0 4px;
          
          button {
            width: 100%;
            height: 100%;
            border-radius: 0;
            background: #D8D8D8;
            opacity: 1;
            padding: 0;
            
            &::before {
              display: none;
            }
          }
          
          &.slick-active button {
            background: #1890ff;
          }
        }
      }
    }

    .carousel-page {
      cursor: default;
    }
  }