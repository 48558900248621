.shops-container {
  padding: 20px;

  .ant-card {
    margin-bottom: 16px;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }
  }

  .selected-shop {
    border: 2px solid #1890ff;
  }

  .current-shop {
    display: block;
    margin-top: 20px;
    font-size: 18px;
  }
}