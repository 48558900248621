.target-review
  box-sizing: border-box
  width: 100%
  min-height: 100%
  padding: 15px 20px
  background-color: #ffffff

  .ant-table-wrapper
    overflow-x: auto
  
