.live-broadcast-list {
  min-height: 100%;
  padding: 15px 20px; 
  background-color: #ffffff;

  .filters {
    margin-bottom: 20px;
  }

  .avatar-placeholder {
    width: 40px;
    height: 40px;
    background-color: #f0f0f0;
    border-radius: 50%;
  }

  .ant-table-wrapper {
    overflow-x: auto;
  }
}