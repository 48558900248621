.layout {
  display: flex;
  width: 100vw;
  height: 100vh; /* 确保布局高度为屏幕高度 */
}

.layout .sidebar {
  background-color: #001529; /* 与 SlideMenu 和 HeaderModule 一致 */
}

.right-content {
  flex: 1;
}

.main-content {
  width: 100%; /* 与 right-content 保持一致的宽度 */
  height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  background: #eee;
}