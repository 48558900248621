
/* 变量定义 */
:root {
  --primary-color: #1890ff;
  --border-color: #d9d9d9;
  --text-color: #000000;
  --text-secondary: rgba(0, 0, 0, 0.45);
}

/* 布局样式 */
.roi-calculator {
  width: 100%;
}

/* 标题样式 */
.roi-title {
  margin-top: 0;
  font-size: 17px;
}

.section-title {
  margin-bottom: 5px;
}

.section-title--with-note {
  margin-bottom: 5px;
  margin-top: 5px;
}

.section-note {
  margin-left: 15px;
  font-weight: 400;
}

/* 分隔线样式 */
.divider {
  width: 100%;
  height: 1px;
  background: var(--border-color);
  margin-bottom: 16px;
  position: relative;
}

.divider--sm {
  margin-bottom: 5px;
}

.divider__highlight {
  height: 100%;
  background: var(--primary-color);
  position: absolute;
  left: 0;
  top: 0;
}

.divider__highlight--lg {
  width: 90px;
}

.divider__highlight--md {
  width: 54px;
}

.divider__highlight--sm {
  width: 36px;
}

/* 表单样式 */
.form-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 850px;
  margin: 0;
}

.form-item {
  margin-bottom: 10px;
}

.form-input {
  width: 400px;
}

.form-col {
  width: 450px;
}

/* 文本样式 */
.text-wrapper {
  margin: 5px 0;
}

.text-value {
  color: var(--primary-color);
  font-size: 20px;
}

.text-value--bold {
  font-weight: bold;
}

.text-label {
  font-size: 16px;
}

.text-percentage {
  margin-left: 50px;
}
